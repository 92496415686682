import { Form, Input, Row, Col, Switch, Select, DatePicker, InputNumber, Divider, Button } from 'antd';
import { useRef, useEffect, useState } from 'react';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../system/WebEditUniversalNewEx';
import fetchApi from '../../utils/api/fetchApi';
import getList from '../universal/getList';
import dayjs from 'dayjs';
import arrayHandle from '../../utils/baseLib/arrayHandle';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm();
    const { data } = props;
    const ref = useRef<any>();
    const [membershipTypeData, setMembershipTypeData] = useState<any>();
    const [verificationMethodData, setVerificationMethodData] = useState<any>();
    const [membershipStatusData, setMembershipStatusData] = useState<any>();
    const [addMemberRuleData, setAddMemberRuleData] = useState<any>();
    const [merchantData, setMerchantData] = useState<any>();
    const [newMerchant, setNewMerchant] = useState<any>();
    const [merchantStoreData, setMerchantStoreData] = useState<any>();
    const [newMerchantStore, setNewMerchantStore] = useState<any>();
    const [isBatchAddMembers, setIsBatchAddMembers] = useState(false);
    const [isAddSuffix, setIsAddSuffix] = useState(false);
    const [isMemberIdSuffix, setIsMemberIdSuffix] = useState(false);
    const [initialNumber, setInitialNumber] = useState(false);
    /** 后端数据defaultService */
    let defaultService: any = '';
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'membershipType',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setMembershipTypeData(res.data);
                    }
                })
            fetchApi.GET(defaultService,
                {
                    apiId: 'membership',
                    apiExtend: 'getDistinct'
                }).then(res => {
                    if (res.success) {
                        const { merchant, merchantStore } = res.data;
                        setMerchantData(merchant);
                        setMerchantStoreData(merchantStore);
                    }
                })
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'verificationMethod@membershipStatus@addMemberRule',
                    apiExtend: 'showLists'
                }).then(res => {
                    if (res.success) {
                        const { verificationMethod, membershipStatus, addMemberRule } = res.data;
                        setVerificationMethodData(verificationMethod);
                        setMembershipStatusData(membershipStatus);
                        setAddMemberRuleData(addMemberRule);
                    }
                })
        };
        fetchData();
    }, []);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const handleMerchant = async (e: any) => {
        const arr = await arrayHandle.find(merchantData, e, {
            queryNames: 'merchantId', isMustEqual: true
        })
        if (arr.length >= 1) {
            const { merchantName } = arr[0];
            form.setFieldValue('merchantName', merchantName);
        }
    };

    const handleMerchantStore = async (e: any) => {
        const arr = await arrayHandle.find(merchantStoreData, e, {
            queryNames: 'merchantStoreId', isMustEqual: true
        })
        if (arr.length >= 1) {
            const { merchantStoreName } = arr[0];
            form.setFieldValue('merchantStoreName', merchantStoreName);
        }
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'membership',
        serviceId: 'membership',
        dateColumnName: ['pointsExpire', 'giftsExpire', 'expireDate']
    };

    /** id禁用 */
    const idDisabled = () => {
        let res = false;
        if (isBatchAddMembers === false) {
            res = data.type === 'NEW' ? false : true;
        } else {
            res = true;
        }
        return res;
    };

    /**
     * initialNumber禁用
     * @param options 选项
     */
    const initialNumberDisabled = (options: {
        /** 当前批量添加会员 */
        currentIsBatchAddMembers?: boolean,
        /** 当前批量添加会员的规则 */
        currentAddMemberRuleId?: number
    }) => {
        if (typeof options === 'undefined') {
            options = {};
        }
        let { currentIsBatchAddMembers, currentAddMemberRuleId } = options;
        currentIsBatchAddMembers = typeof currentIsBatchAddMembers !== 'undefined' ? currentIsBatchAddMembers : isBatchAddMembers;
        currentAddMemberRuleId = typeof currentAddMemberRuleId !== 'undefined' ? currentAddMemberRuleId : form.getFieldValue('addMemberRuleId');
        if (currentIsBatchAddMembers === false) {
            setInitialNumber(false);
        } else {
            switch (currentAddMemberRuleId) {
                case 1:
                case 2:
                    setInitialNumber(true);
                    break;
                default:
                    setInitialNumber(false);
                    break;
            }
        }
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '会员'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={8}>
                            <Form.Item
                                label="ID"
                                tooltip='会员id，不输入自动生成'
                                name="id">
                                <Input disabled={idDisabled()} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="姓名"
                                name="membershipName"
                                rules={[{ required: !isBatchAddMembers, message: '请输入姓名!' }]}>
                                <Input disabled={isBatchAddMembers} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="类别"
                                name="membershipTypeId"
                                rules={[{ required: true, message: '请选择类别!' }]}>
                                <Select
                                    showSearch
                                    placeholder="类别"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(membershipTypeData, { itemName: 'typeName' })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="积分"
                                name="points">
                                <InputNumber style={{ width: 140 }} />
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="积分过期"
                                name="pointsExpire">
                                <DatePicker format="YYYY-MM-DD" style={{ width: 140 }} />
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                label="验证方法"
                                name="verificationMethodId"
                                initialValue={0}
                                rules={[{ required: true, message: '请选择验证方法!' }]}>
                                <Select
                                    showSearch
                                    placeholder="验证方法"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(verificationMethodData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="赠送"
                                name="gifts">
                                <InputNumber style={{ width: 140 }} />
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="赠送过期"
                                name="giftsExpire">
                                <DatePicker format="YYYY-MM-DD" style={{ width: 140 }} />
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                label="密码"
                                name="password">
                                <Input.Password />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="余额"
                                name="balance">
                                <InputNumber style={{ width: 140 }} />
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="到期日"
                                name="expireDate"
                                tooltip='会员到期时间，默认：当前日期+3年'
                                initialValue={dayjs().add(3, 'years')}
                                rules={[{ required: true, message: '请输入到期日!' }]}>
                                <DatePicker format="YYYY-MM-DD" style={{ width: 125 }} />
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                label="微信"
                                name="unionId"
                                tooltip='绑定微信'>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={14}>
                            <Form.Item
                                label="商户id"
                                name="merchantId"
                                tooltip='关联商户id，查询时使用'
                                rules={[{ required: true, message: '请选择商户id!' }]}>
                                <Select
                                    showSearch={true}
                                    placeholder="商户id"
                                    optionFilterProp="children"
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                id:<Input style={{ flex: 'auto' }}
                                                    onChange={(e) => setNewMerchant({
                                                        ...newMerchant,
                                                        merchantId: e.target.value
                                                    })} />
                                                name:<Input style={{ flex: 'auto' }}
                                                    onChange={(e) => setNewMerchant({
                                                        ...newMerchant,
                                                        merchantName: e.target.value
                                                    }
                                                    )} />
                                                <Button type='link'
                                                    onClick={() => {
                                                        if (typeof newMerchant !== 'undefined') {
                                                            const newArr = arrayHandle.merge(merchantData, [newMerchant], {
                                                                keyIdName: 'merchantId'
                                                            })
                                                            setMerchantData(newArr);
                                                        }
                                                    }}>新增</Button>
                                            </div>
                                        </div>
                                    )}
                                    onChange={e => handleMerchant(e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(merchantData, { itemId: 'merchantId', itemName: 'merchantName', isItemNameIncludeId: true })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="商户名"
                                name="merchantName"
                                rules={[{ required: true, message: '请输入商户名!' }]}>
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={14}>
                            <Form.Item
                                label="商户店id"
                                name="merchantStoreId"
                                tooltip='关联商户店id，查询时使用'
                                rules={[{ required: true, message: '请选择商户店id!' }]}>
                                <Select
                                    showSearch={true}
                                    placeholder="商户店id"
                                    optionFilterProp="children"
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                id:<Input style={{ flex: 'auto' }}
                                                    onChange={(e) => setNewMerchantStore({
                                                        ...newMerchantStore,
                                                        merchantStoreId: e.target.value
                                                    })} />
                                                name:<Input style={{ flex: 'auto' }}
                                                    onChange={(e) => setNewMerchantStore({
                                                        ...newMerchantStore,
                                                        merchantStoreName: e.target.value
                                                    }
                                                    )} />
                                                <Button type='link'
                                                    onClick={() => {
                                                        if (typeof newMerchantStore !== 'undefined') {
                                                            const newArr = arrayHandle.merge(merchantStoreData, [newMerchantStore], {
                                                                keyIdName: 'merchantStoreId'
                                                            })
                                                            setMerchantStoreData(newArr);
                                                        }
                                                    }}>新增</Button>
                                            </div>
                                        </div>
                                    )}
                                    onChange={e => handleMerchantStore(e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(merchantStoreData, { itemId: 'merchantStoreId', itemName: 'merchantStoreName', isItemNameIncludeId: true })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="商户店名"
                                name="merchantStoreName"
                                rules={[{ required: true, message: '请输入商户店名!' }]}>
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="会员状态"
                                name="membershipStatusId"
                                initialValue={0}
                                rules={[{ required: true, message: '请选择会员状态!' }]}>
                                <Select
                                    showSearch
                                    placeholder="会员状态"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(membershipStatusData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Divider />

                        <Col span={5}>
                            <Form.Item
                                label="批量添加"
                                name="isBatchAddMembers"
                                tooltip='批量添加会员'
                                valuePropName="checked">
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否"
                                    disabled={data.type === 'NEW' ? false : true}
                                    onChange={(e: boolean) => {
                                        setIsBatchAddMembers(e);
                                        if (e === false) {
                                            setIsMemberIdSuffix(e);
                                        } else {
                                            if (isAddSuffix === true) {
                                                setIsMemberIdSuffix(e);
                                            }
                                        }
                                        initialNumberDisabled({
                                            currentIsBatchAddMembers: e
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="会员数"
                                name="numberOfMembers"
                                initialValue={1}
                                tooltip='批量添加会员的数量'
                                rules={[{ required: isBatchAddMembers, message: '请输入会员数!' }]}>
                                <Input disabled={!isBatchAddMembers} />
                            </Form.Item>
                        </Col>

                        <Col span={13}>
                            <Form.Item
                                label="规则"
                                name="addMemberRuleId"
                                tooltip='批量添加会员的规则'
                                rules={[{ required: isBatchAddMembers, message: '请选择规则!' }]}>
                                <Select
                                    showSearch
                                    placeholder="规则"
                                    optionFilterProp="children"
                                    disabled={!isBatchAddMembers}
                                    onChange={(e) => initialNumberDisabled({
                                        currentAddMemberRuleId: e
                                    })}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(addMemberRuleData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="固定编号"
                                name="fixedNumber"
                                tooltip='会员id头的固定编号，例如20011XXXX，20011就是固定编号'
                                rules={[{ required: initialNumber, message: '请输入固定编号!' }]}>
                                <Input disabled={!initialNumber} />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="新固定编号"
                                name="isAddSuffix"
                                valuePropName="checked">
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否"
                                    onChange={(e: boolean) => {
                                        setIsAddSuffix(e);
                                        setIsMemberIdSuffix(e)
                                    }}
                                    disabled={!isBatchAddMembers}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="序号长度"
                                name="serialNumberLength"
                                tooltip='会员id头的序号，例如XXXXX0000001，序号0000001的长度：7'
                                initialValue={7}
                                rules={[{ required: initialNumber, message: '请输入序号长度!' }]}>
                                <Input disabled={!initialNumber} />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="添加尾号"
                                name="isAddSuffix"
                                valuePropName="checked">
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否"
                                    onChange={(e: boolean) => {
                                        setIsAddSuffix(e);
                                        setIsMemberIdSuffix(e)
                                    }}
                                    disabled={!isBatchAddMembers}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label=" "
                                name="memberIdSuffix"
                                initialValue={'888'}
                                tooltip='会员ID尾号，如：XXXXXXXX888'
                                rules={[{ required: isMemberIdSuffix, message: '请输入尾号!' }]}>
                                <Input disabled={(!isMemberIdSuffix)} />
                            </Form.Item>
                        </Col>

                       

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;