/** 开发模式 */
const isDevelopmentEnvironment = process.env.REACT_APP_NODE_ENV === "development";

/** 服务器地址 */
const serverUrl =
    (isDevelopmentEnvironment
        ? process.env.REACT_APP_DEV_SERVERURL
        : process.env.REACT_APP_SERVERURL)
    || "http://localhost:3003/test";

/** 地址配置文件 */
const systematic = {
    /** 服务器地址 */
    serverUrl,
    /** 后端数据bmService */
    bmService: serverUrl + '/bm/service',
    /** 后端数据payService */
    payService: serverUrl + '/pay/service',
    /** 后端数据messageService */
    messageService: serverUrl + '/message/service',
    /** 后端数据membershipService */
    membershipService: serverUrl + '/membership/service',
};

export default systematic;
