import { defaultRtn, sortByType } from '../../types/general';

/** 数组处理 */
const arrayHandle = async () => { };

/**
 * 寻找
 * @param arr 数组
 * @param findValue 寻找的值
 * @param options 选项
 * @returns 
 */
arrayHandle.find = (
    arr: Array<any>,
    findValue: any,
    options?: {
        /** 查询的结象数组中的名称，用@分割，例如：id@name@.... */
        queryNames?: string,
        /** 寻找的值是否必需等于数组的值 */
        isMustEqual?: boolean
    }) => {
    if (typeof findValue === 'undefined' || findValue === '') {
        return arr;
    }
    if (typeof options === 'undefined') {
        options = {};
    }
    const { queryNames, isMustEqual } = options;
    /** 返回数据 */
    let res: any = [];
    for (let i = 0; i < arr.length; i++) {
        if (queryNames) {
            const queryArray = queryNames.split('@');
            for (let keyIdName of queryArray) {
                if (keyIdName) {
                    try {
                        const item = typeof keyIdName === 'undefined' ? arr[i] : arr[i][keyIdName];
                        if (item) {
                            if (isMustEqual === true) {
                                if (item === findValue) {
                                    res = res.concat(arr[i]);
                                    break;
                                }
                            } else {
                                if (item.includes(findValue) === true) {
                                    res = res.concat(arr[i]);
                                    break;
                                }
                            }
                        }
                    }
                    catch { }
                }
            }
        }
    }
    return res;
};

/**
 * 查找数组中匹配的数据
 * @param arr 数组
 * @param idValue 匹配id值
 * @param options 选项
 * @returns 返回找到第一个值的数据对象 [data-数据, index-索引]
 * @example const findItem = await arrayHandle.findValue(
 * currentCallerToday, 
 * callerId, 
 * { keyIdName: 'findId', 
 *   targetId: 'isAnswered' });
 */
arrayHandle.findValue = async (arr: Array<any>, idValue: any,
    options?: {
        /** 关键id名称 */
        keyIdName?: string,
        /** 匹配id行的目标Id，如果存在，返回值，否则返回数组内容 */
        targetId?: string
    }
) => {
    if (typeof options === 'undefined') {
        options = {};
    }
    let { keyIdName, targetId } = options;
    /** 返回 */
    var res = [undefined, -1];
    if (typeof arr === 'undefined') { return res; }
    if (arr.length === 0) { return res; }
    /** 索引 */
    const index = arr.findIndex((item: any) => {
        return (typeof keyIdName === 'undefined') ? item === idValue : item[keyIdName] === idValue;
    });
    /** 数据 */
    let data;
    if (index > -1) {
        data = arr[index];
        if (targetId) {
            data = data[targetId];
        }
    }
    return [data, index];
};

/**
 * 删除数组中匹配的数据
 * @param arr 数组
 * @param idName 匹配id名称
 * @param idValue 匹配id值
 * @returns 返回删除后的数组
 * @example arrayHandle.deleteValue(rows,'id','xx')
 */
arrayHandle.deleteValue = async (arr: Array<any>, idName: any, idValue: any): Promise<Array<any>> => {
    if (typeof arr !== 'undefined') {
        if (arr.length === 0) { return arr; }
        /** 索引 */
        const index = arr.findIndex((item: any) => {
            return item[idName] === idValue;
        });
        if (index > -1) {
            //删除数组方法，不要用delete
            arr.splice(index, 1);
        }
    }
    return arr;
};

/**
 * 数组合并，先对旧数组替换为新数组数据，对新数组去重，然后再concat合并新数据
 * @param oldArray 旧数组对象
 * @param newArray 新数组对象
 * @param options 选项
 */
arrayHandle.merge = (oldArray: Array<any>, newArray: Array<any>,
    options?: {
        /** 去重关键id名称 */
        keyIdName?: string,
        /** 旧数组去重关键id名称(优先级高于keyIdName参数) */
        oldKeyIdName?: string,
        /** 新数组去重关键id名称(优先级高于keyIdName参数) */
        newKeyIdName?: string,
        /** 数据是否合并，true为合并，false为替换 */
        isMerge?: boolean,
        /** 数组头部插入 */
        isUnshift?: boolean
    }) => {
    if (Array.isArray(oldArray) === true && Array.isArray(newArray) === true) {
        if (typeof options === 'undefined') {
            options = {};
        }
        let { keyIdName, oldKeyIdName, newKeyIdName, isMerge, isUnshift } = options;
        oldKeyIdName = typeof oldKeyIdName !== 'undefined' ? oldKeyIdName : keyIdName;
        newKeyIdName = typeof newKeyIdName !== 'undefined' ? newKeyIdName : keyIdName;
        for (var i = 0; i < oldArray.length; i++) {
            const index = newArray.findIndex((item: any) => {
                /** 新内容 */
                const newItem = (typeof newKeyIdName === 'undefined') ? item : item[newKeyIdName];
                /** 旧内容 */
                const oldItem = (typeof oldKeyIdName === 'undefined') ? oldArray[i] : oldArray[i][oldKeyIdName];
                return (typeof newItem === 'object' ? JSON.stringify(newItem) : newItem) ===
                    (typeof oldItem === 'object' ? JSON.stringify(oldItem) : oldItem);
            });
            if (index > -1) {
                if (isMerge === true) {
                    oldArray[i] = { ...oldArray[i], ...newArray[index] };
                } else {
                    //替换内容
                    oldArray[i] = newArray[index];
                }
                //删除
                newArray.splice(index, 1);
            }
        }
        if (newArray.length > 0) {
            if (isUnshift === false) {
                oldArray = oldArray.concat(newArray);
            }
            else {
                oldArray.unshift(...newArray);
            }
        }
    }
    return oldArray;
};

/**
 * 数组替换，根据替换数组的替换id及替换变量替换目标数组中的变量，根据需求再合并数组
 * @param arr 目标数组
 * @param replaceArray 替换数组
 * @param options 选项
 */
arrayHandle.replace = (arr: Array<any>, replaceArray: Array<any>,
    options?: {
        /** 替换id，默认：id */
        replaceIdName?: string,
        /** 替换变量 */
        replaceValue?: any,
        /** 找目标数组中的关键id，默认：id */
        keyIdName?: string,
        /** 是否合并数组，默认：true */
        isMerge?: boolean
    }) => {
    if (Array.isArray(arr) === true && Array.isArray(replaceArray) === true) {
        if (typeof options === 'undefined') {
            options = {};
        }
        let { replaceIdName, replaceValue, keyIdName, isMerge } = options;
        if (typeof replaceIdName === 'undefined') {
            replaceIdName = 'id';
        }
        if (typeof keyIdName === 'undefined') {
            keyIdName = 'id';
        }
        if (typeof isMerge === 'undefined') {
            isMerge = true;
        }
        for (var i = 0; i < arr.length; i++) {
            // eslint-disable-next-line no-loop-func
            const index = replaceArray.findIndex((item: any) => {
                return item[keyIdName] === arr[i][keyIdName] && item[replaceIdName] === replaceValue;
            });
            if (index > -1) {
                //替换内容
                arr[i] = replaceArray[index];
                //删除replaceArray
                replaceArray.splice(index, 1);
            }
        }
        if (isMerge === true) {
            arr = arr.concat(replaceArray);
        }
    }
    return arr;
};

/**
 * 数组排序(递归法)
 * @param arr 数组
 * @param options 选项
 * @returns 
 */
arrayHandle.sort = (arr: Array<any>, options?: {
    /** 排序依据属性，如错误，则不会排序 */
    prop?: string,
    /** 排序模式，默认：desc */
    sortBy?: sortByType
}): Array<any> => {
    if (typeof options === 'undefined') {
        options = {};
    }
    let { prop, sortBy } = options;
    if (typeof sortBy === 'undefined') {
        sortBy = 'desc';
    }
    /** 返回的数组 */
    let res = arr;
    // 没有元素或只有1个元素 无需排序
    if (arr.length > 1) {
        try {
            /** 第一个元素作为基准值 */
            let piv;
            if (typeof prop !== 'undefined') {
                piv = arr[0][prop];
            }
            else {
                piv = arr[0];
            }
            /** 左侧 < 基准值数组 */
            let Larr = [];
            /** 右侧 > 基准值数组 */
            let Rarr = [];
            for (let i = 1; i < arr.length; i++) {
                /** 比较值 */
                let comparePiv;
                if (typeof prop !== 'undefined') {
                    comparePiv = arr[i][prop];
                }
                else {
                    comparePiv = arr[i];
                }
                if (comparePiv < piv) {
                    Larr.push(arr[i]);
                } else {
                    Rarr.push(arr[i]);
                }
            }
            // 递归调用sort 结果返回新数组
            res = [...arrayHandle.sort(Rarr, { prop }), arr[0], ...arrayHandle.sort(Larr, { prop })];
            switch (sortBy) {
                case 'asc':
                    //反转数组中元素的顺序：
                    res = res.reverse();
                    break;
            }
        }
        catch {
            res = ['参数错误!'];
        }
    }
    return res;
};

/**
 * 数组截取
 * @param arr 数组
 * @param truncateLenght 截取长度
 * @param direction ? 截取方向，是否截取右长度
 * @example let arr = ['a', 'b', 'c', 'd', 'e', 'f','g','h'];
 * arrayHandle.truncate(arr, 4) return 'a','b','c','d';
 * arrayHandle.truncate(arr, 4, true) return 'e','f','g','h';
 */
arrayHandle.truncate = (arr: Array<any>,
    truncateLenght: number, direction?: boolean) => {
    const len = arr.length;
    const i = len - truncateLenght;
    if (i > 0) {
        let tArray;
        if (direction === true) {
            tArray = arr.slice(i, len);
        }
        else {
            tArray = arr.slice(0);
            tArray.splice(truncateLenght, i);
        }
        return tArray;
    }
    else {
        return arr;
    }
};

/**
 * 数组重排序
 * @param arr 数组
 * @param index 数据中的索引
 * @param prefix ? 排序是否放在最前
 * @returns 
 */
arrayHandle.reorder = (arr: Array<any>,
    index: number, prefix?: boolean) => {
    const len = arr.length;
    if (len === 0 || index >= len) {
        return arr;
    }
    else {
        let res;
        let newItem = arr[index];
        let tArray = arr.slice(0);
        tArray.splice(index, 1);
        if (prefix === true) {
            res = [newItem].concat(tArray);
        } else {
            res = tArray.concat(newItem);
        }
        return res;
    }
};

/**
 * 比较两个数组是否相同
 * @param oneArr 数组一
 * @param twoArr 数组二
 * @param options 选项
 */
arrayHandle.comparison = async (oneArr: Array<any>, twoArr: Array<any>, options?: {
    /** 如果存在keyIdName，则使用数组[keyIdName]比较 */
    keyIdName?: string
}) => {
    /** 返回 */
    const rtn = defaultRtn();
    if (typeof options === 'undefined') {
        options = {};
    }
    if (typeof oneArr === 'undefined') {
        rtn.message = 'oneArr is undefined;'
    }
    if (typeof twoArr === 'undefined') {
        rtn.message += 'twoArr is undefined;';
    }
    const { keyIdName } = options;
    for (const itemOne of oneArr) {
        let k = itemOne;
        const oneComparison = await twoArr.find(element => {
            if (typeof keyIdName !== 'undefined') {
                if (typeof element !== 'undefined') {
                    element = element[keyIdName];
                }
                if (typeof itemOne !== 'undefined') {
                    k = itemOne[keyIdName];
                }
            }
            return (typeof element === 'object' ? JSON.stringify(element) : element) ===
                (typeof k === 'object' ? JSON.stringify(k) : k)
        });
        if (typeof oneComparison === 'undefined') {
            rtn.success = false;
            rtn.message += `twoArr不包括oneArr.${typeof k === 'object' ? JSON.stringify(k) : k}；`;
            break;
        }
        rtn.success = true;
    }
    if (rtn.success === true) {
        for (let itemTwo of twoArr) {
            let k = itemTwo;
            const twoComparison = await oneArr.find(element => {
                if (typeof keyIdName !== 'undefined') {
                    if (typeof element !== 'undefined') {
                        element = element[keyIdName];
                    }
                    if (typeof itemTwo !== 'undefined') {
                        k = itemTwo[keyIdName];
                    }
                }
                return (typeof element === 'object' ? JSON.stringify(element) : element) ===
                    (typeof k === 'object' ? JSON.stringify(k) : k)
            });
            if (typeof twoComparison === 'undefined') {
                rtn.success = false;
                rtn.message += `oneArr不包括twoArr.${typeof k === 'object' ? JSON.stringify(k) : k}`;
                break;
            }
            rtn.success = true;
        }
    }
    return rtn;
};

export default arrayHandle;