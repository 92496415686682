/**
 * 请求的类型
 * @type GET（SELECT）：从服务器查询，可以在服务器通过请求的参数区分查询的方式。
 * @type POST（CREATE）：在服务器新建一个资源，调用insert操作。
 * @type PUT（UPDATE）：在服务器更新资源，调用update操作。
 * @type DELETE（REMOVE）：从服务器删除资源，调用delete语句
 * @type HEAD：请求一个与GET请求的响应相同的响应，但没有响应体.
 * @type CONNECT ：建立一个到由目标资源标识的服务器的隧道。
 * @type OPTIONS ： 用于描述目标资源的通信选项。
 * @type TRACE ： 沿着到目标资源的路径执行一个消息环回测试。
 * @type PATCH ： 用于对资源应用部分修改。
 */
export declare type methodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD' | 'CONNECT'
    | 'OPTIONS' | 'TRACE' | 'PATCH';

/** 排序方式类型，asc：升序；desc：降序； */
export declare type sortByType = 'asc' | 'desc';

/** Uuid短码长度类型 */
export declare type idLenghtType = 8 | 16 | 32;

/** 字母类型 uppercase：大写，lowercase：小写，all：所有 */
export declare type letterFixType = 'uppercase' | 'lowercase' | 'all';

/** snowflakeId 前后缀数据类型 */
export declare type snowflakeIdFixType = "prefix" | "suffix";

/** 返回数据类型 */
export declare type rtnType = {
    /** 成功标志 */
    success: boolean,
    /** 数据 */
    data?: any,
    /** 错误代码 */
    errorCode?: string,
    /** 信息 */
    message?: string,
};

/**
 * 默认rtn 
 * @param options 选项 {
 * success: false,
 * data: undefined,
 * errorCode: undefined,
 * message: ''
 * }
 * @returns 
 */
export const defaultRtn = (options?: rtnType) => {
    if (typeof options === 'undefined') {
        options = {
            success: false
        };
    }
    const {
        success, data, errorCode, message
    } = options;
    const rtn: rtnType = {
        /** 成功标志 */
        success: (typeof success !== 'undefined') ? success : false,
        /** 数据 */
        data: (typeof data !== 'undefined') ? data : undefined,
        /** 错误代码 */
        errorCode: (typeof errorCode !== 'undefined') ? errorCode : '',
        /** 信息 */
        message: (typeof message !== 'undefined') ? message : ''
    }
    return rtn;
};
