import { Form, Input, Switch, Row, Col, Select, Button } from 'antd';
import { useRef, useState, useEffect } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import objectHandle from '../../../utils/baseLib/objectHandle';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const UserEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    const [roleData, setRoleData] = useState<any>();
    const [initialRolesId, setInitialRolesId] = useState<any>([]);
    const [brandData, setBrandData] = useState<any>();
    const [userGroupData, setUserGroupData] = useState<any>();

    /** 后端数据defaultService */
    let defaultService: any = '';
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            ref.current.row.then(async (res: any) => {
                if (res.success === true) {
                    const rolesId = res.data.rolesId;
                    let rolesRows;
                    if (objectHandle.isEmpty(rolesId) === false) {
                        rolesRows = rolesId.split('@');
                    }
                    setInitialRolesId(rolesRows);
                    form.setFieldsValue({
                        rolesId: rolesRows
                    })
                }
            })
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'role',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setRoleData(res.data);
                    }
                });
            fetchApi.GET(defaultService,
                {
                    apiId: 'brand',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setBrandData(res.data)
                    }
                })
            fetchApi.GET(defaultService,
                {
                    apiId: 'userGroup',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setUserGroupData(res.data)
                    }
                })
        };
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'user',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '用户'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={4}>
                            <Form.Item
                                label="ID"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="姓名"
                                name="userName"
                                rules={[{ required: true, message: '请输入姓名!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="品牌"
                                name="brandId"
                                rules={[{ required: true, message: '请选择品牌!' }]}>
                                <Select
                                    showSearch
                                    placeholder="选择品牌"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(brandData, 'brandName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="用户组"
                                name="userGroupId">
                                <Select
                                    showSearch
                                    placeholder="用户组"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(userGroupData, 'userGroupName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="手机"
                                name="phone">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="角色"
                                name="rolesId"
                                initialValue={initialRolesId} >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择角色"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(roleData, 'roleName', 'id', undefined, false)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="地址"
                                name="address">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="管理员"
                                name="isAdmin"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    disabled={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="检查登录设备"
                                name="isCheckLoginDevice"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="登录检查">
                                <Button id="machineCode">登录设备</Button>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default UserEdit;