import React from 'react';
import ReactDOM from 'react-dom/client'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { ConfigProvider, theme } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import locale from 'antd/locale/zh_CN';
import dayjs from 'dayjs';

dayjs.locale('zh-cn')
const store = configureStore();
const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container!);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ConfigProvider
      locale={locale}
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
  // document.getElementById('root')
);

// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';
// import ReactDOM from 'react-dom/client';
// import configureStore from './store/configureStore';
// import { Provider } from 'react-redux';
// import { BrowserRouter } from 'react-router-dom';
// import { ConfigProvider, theme } from 'antd';
// import 'dayjs/locale/zh-cn';
// import locale from 'antd/locale/zh_CN';
// import dayjs from 'dayjs';

// dayjs.locale('zh-cn')
// const store = configureStore();
// const container = document.getElementById('root') as HTMLElement;
// const root = ReactDOM.createRoot(container!);
// root.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <ConfigProvider
//         locale={locale}
//         theme={{
//           algorithm: theme.darkAlgorithm,
//         }}
//       >
//         {/* <Suspense fallback={<>loading</>}> */}
//         <App />
//         {/* </Suspense> */}
//       </ConfigProvider>
//     </BrowserRouter>
//   </Provider>

// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
