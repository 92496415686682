import { List, Modal } from 'antd';
import fetchApi from "../../../utils/api/fetchApi";
import arrayHandle from "../../../utils/baseLib/arrayHandle";
import antMessage from '../../../utils/extend/AntdNotification';
import systematic from '../../../config/systematic';

/** 后端数据defaultService */
const defaultService = systematic.bmService;

/** 物流 */
const logistics = async () => { };

/**
 * 物流信息查询
 * @param logisticsOrderId 物流订单id
 */
logistics.getOrder = async (logisticsOrderId: string) => {
    /** 获取物流信息 */
    const res = await fetchApi.GET(defaultService,
        {
            apiId: 'logistics',
            apiVariable: logisticsOrderId,
            apiExtend: 'getOrder'
        });
    console.log(res);

};

/**
 * 物流信息查询
 * @param logisticsOrderId 物流订单id
 */
logistics.routeQuery = async (logisticsOrderId: string) => {
    try {
        /** 获取物流信息 */
        const res = await fetchApi.GET(defaultService,
            {
                apiId: 'logistics',
                apiVariable: logisticsOrderId,
                apiExtend: 'routeQuery'
            });
        let message: any;
        let routes: Array<any> = [];
        if (res.success === true) {
            message = res.data.logisticsInfo.apiResultData.msgData.routeResps[0].mailNo +
                ' 收货人：' + res.data.receiver;
            routes = res.data.logisticsInfo.apiResultData.msgData.routeResps[0].routes;
            routes = arrayHandle.sort(routes, { prop: 'acceptTime', sortBy: 'desc' });
        }
        /** 物流内容 */
        const content = <List
            size='small'
            itemLayout="horizontal"
            dataSource={routes}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                        title={item.acceptTime + ' - ' + item.acceptAddress}
                        description={item.remark}
                    />
                </List.Item>
            )}
        />
        Modal.info({
            title: message,
            content,
            onOk() { },
        });
    }
    catch (err: any) {
        antMessage('error', '错误，无法获取物流信息', 'error，请检查');
        console.log(err);
    }
};
export default logistics;