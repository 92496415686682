import { RouteObject } from "react-router-dom"
import MembershipType from '../components/membershipCenter/MembershipType';
import Membership from '../components/membershipCenter/Membership';

const membershipRouters: RouteObject[] = [
    {
        path: "/membershipType",
        element: <MembershipType />,
    },
    {
        path: "/membership",
        element: <Membership />,
    },
];


export default membershipRouters;