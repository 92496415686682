import { Form, Row, Col, Select, Button, Anchor } from 'antd';
import { useEffect, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import systematic from '../../../config/systematic';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification'
import stringHandle from '../../../utils/baseLib/stringHandle';

/** 后端数据defaultService */
const defaultService = systematic.bmService;

/**
 * 系统配置
 * @returns 
 */
const Configs = () => {
    const [form] = Form.useForm(); //form 
    const [modalVisible, setModalVisible] = useState(true);
    const [nodesData, setNodesData] = useState();
    const [divCmpt, setDivCmpt] = useState<any>();
    const [divCmpt2, setDivCmpt2] = useState<any>();
    const [buttonCmpt, setButtonCmpt] = useState<any>();
    /** 保存数据方法 */
    var saveFunction: Function;

    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {
            let arr: any = [];
            arr = arr.concat({
                key: 'general',
                href: '#general',
                title: '通用',
                id: 'general'
            })
            arr = arr.concat({
                key: 'files',
                href: '#files',
                title: '文件',
                id: 'files'
            })
            setNodesData(arr);
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    /** 确定 */
    const handleOk = async (e: any) => {
        setModalVisible(false)
    };

    /**
     * 获取文件服务器 服务号 列表
     * @param filesServerId 服务器id
     */
    const getFilesServiceList = async (filesServerId: string) => {
        let filesServiceList: any;
        try {
            const res = await fetchApi.GET(defaultService, {
                apiId: 'filesServer',
                apiExtend: 'getDefaultServer',
                apiVariable: filesServerId
            });
            if (res.success === true) {
                const { api } = res.data;
                const { manageServiceUrl
                } = api;
                const filesServiceRes = await fetchApi.GET(manageServiceUrl, {
                    apiId: 'service',
                    apiExtend: 'showList'
                })
                if (filesServiceRes.success === true) {
                    filesServiceList = filesServiceRes.data;
                }
            }
        }
        catch (err: any) {
            antMessage('error', '无法获取数据！', err.message);
        }
        setDivCmpt2(<Col span={12}>
            <Form.Item
                label="默认服务id"
                name="filesServiceId">
                <Select
                    showSearch
                    placeholder="服务id"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {getList.generalEx(filesServiceList, {
                        itemName: 'serviceName'
                    })}
                </Select>
            </Form.Item>
        </Col>)
    };

    /**
     * 初始化组件
     * @param nodeId 节点id
     */
    const getDivComponent = async (nodeId: any) => {
        nodeId = stringHandle.removeExtension(nodeId, '#', 3);
        //获取节点数据
        /** 所选择的filesServer id */
        let filesServerId
        /** 所选择的service id */
        let filesServiceId
        try {
            /** config res */
            const configRes = await fetchApi.GET(defaultService,
                {
                    apiId: 'configNodes',
                    apiExtend: 'getConfig',
                    apiData: {
                        id: 'config',
                        nodeId: 'general'
                    }
                })
            if (configRes.success === true) {
                const { general } = configRes.data;
                filesServerId = general.filesServerId;
                filesServiceId = general.filesServiceId;
            }
        } catch (err: any) {
            antMessage('error', '无法获取数据！', err.message);
        }

        switch (nodeId) {
            case 'general': //通用配置节点
                //1) 初始化
                /** 文件服务器列表 */
                let filesServerData;
                try {
                    const filesServerRes = await fetchApi.GET(defaultService, {
                        apiId: 'filesServer',
                        apiExtend: 'showList'
                    });
                    if (filesServerRes.success === true) {
                        filesServerData = filesServerRes.data;
                    }
                }
                catch (err: any) {
                    antMessage('error', '无法获取数据！', err.message);
                }
                //2) 生成组件
                setDivCmpt(<>
                    {/* 以下可以修改 */}
                    <Col span={12}>
                        <Form.Item
                            label="默认文件服务器"
                            name="filesServerId">
                            <Select
                                showSearch
                                placeholder="文件服务器"
                                optionFilterProp="children"
                                onChange={(e) => getFilesServiceList(e)}
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {getList.general(filesServerData, 'serverName')}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* --- */}
                </>);
                //3) 获取节点内容
                //以下可以修改
                getFilesServiceList(filesServerId);
                form.setFieldValue('filesServerId', filesServerId);
                form.setFieldValue('filesServiceId', filesServiceId);
                //--------
                //4) 保存数据
                saveFunction = () => {
                    /** 节点对象 */
                    const nodeObj = {
                        //以下可以修改
                        filesServerId: form.getFieldValue('filesServerId'),
                        filesServiceId: form.getFieldValue('filesServiceId'),
                        //---
                    }
                    fetchApi.PUT(defaultService,
                        {
                            apiId: 'configNodes',
                            apiExtend: 'setConfig',
                            apiData: {
                                id: 'config',
                                nodeId,
                                configName: '系统设置',
                                content: nodeObj
                            }
                        }).then(res => {
                            if (res.success) {
                                antMessage('success', '保存数据成功！', 'success');
                                // setModalVisible(false);
                            }
                        }).catch(err => {
                            antMessage('error', '保存数据失败！', err.message);
                        });
                }
                setButtonCmpt(
                    <Button onClick={() => saveFunction()}>保存数据</Button>
                )
                break;

            case 'files': //文件配置节点
                //1) 初始化
                let filesGroupData;
                try {
                    const res = await fetchApi.GET(defaultService, {
                        apiId: 'filesServer',
                        apiExtend: 'getDefaultServer',
                        apiVariable: filesServerId
                    });
                    if (res.success === true) {
                        const { api } = res.data;
                        const { manageServiceUrl
                        } = api;
                        const filesGroupRes = await fetchApi.GET(manageServiceUrl, {
                            apiId: 'filesGroup',
                            apiExtend: 'showList'
                        }, {
                            serviceId: filesServiceId
                        })
                        if (filesGroupRes.success === true) {
                            filesGroupData = filesGroupRes.data;
                        }
                    }
                }
                catch (err: any) {
                    antMessage('error', '无法获取数据！', err.message);
                }
                //2) 生成组件
                setDivCmpt(<>
                    {/* 以下可以修改 */}
                    <Col span={12}>
                        <Form.Item
                            label="图片广告组"
                            name="webCarouselAdFilesGroupId">
                            <Select
                                showSearch
                                placeholder="文件组"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {getList.general(filesGroupData, 'groupName')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="产品图片组"
                            name="webProductFilesGroupId">
                            <Select
                                showSearch
                                placeholder="文件组"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {getList.general(filesGroupData, 'groupName')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="产品价格图片组"
                            name="webProductPriceFilesGroupId">
                            <Select
                                showSearch
                                placeholder="文件组"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {getList.general(filesGroupData, 'groupName')}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* --- */}
                </>);
                setDivCmpt2(undefined);
                //3) 获取节点内容
                fetchApi.GET(defaultService,
                    {
                        apiId: 'configNodes',
                        apiExtend: 'getConfig',
                        apiData: {
                            id: 'config',
                            nodeId
                        }
                    }).then(res => {
                        if (res.success) {
                            try {
                                const { webCarouselAdFilesGroupId,
                                    webProductFilesGroupId,
                                    webProductPriceFilesGroupId
                                } = res.data[nodeId];
                                //以下可以修改
                                form.setFieldValue('webCarouselAdFilesGroupId', webCarouselAdFilesGroupId);
                                form.setFieldValue('webProductFilesGroupId', webProductFilesGroupId);
                                form.setFieldValue('webProductPriceFilesGroupId', webProductPriceFilesGroupId);
                                //--------
                            }
                            catch (err: any) {
                                antMessage('error', '无法获取数据！', err.message);
                            }
                        }
                    }).catch(err => {
                        antMessage('error', '无法获取数据！', err.message);
                    });
                //4) 保存数据
                saveFunction = () => {
                    /** 节点对象 */
                    const nodeObj = {
                        //以下可以修改
                        webCarouselAdFilesGroupId: form.getFieldValue('webCarouselAdFilesGroupId'),
                        webProductFilesGroupId: form.getFieldValue('webProductFilesGroupId'),
                        webProductPriceFilesGroupId: form.getFieldValue('webProductPriceFilesGroupId'),
                        //---
                    }
                    fetchApi.PUT(defaultService,
                        {
                            apiId: 'configNodes',
                            apiExtend: 'setConfig',
                            apiData: {
                                id: 'config',
                                nodeId,
                                configName: '系统设置',
                                content: nodeObj
                            }
                        }).then(res => {
                            if (res.success) {
                                antMessage('success', '保存数据成功！', 'success');
                                // setModalVisible(false);
                            }
                        }).catch(err => {
                            antMessage('error', '保存数据失败！', err.message);
                        });
                }
                setButtonCmpt(
                    <Button onClick={() => saveFunction()}>保存数据</Button>
                )
                break;
            default:
                setDivCmpt(undefined);
                setDivCmpt2(undefined);
                setButtonCmpt(undefined);
                break;
        }
    };

    return (
        <>
            <AntDraggableModal
                title={'系统设置'}
                open={modalVisible}
                okText='关闭'
                onOk={handleOk}
                onCancel={handleOk}
                cancelButtonProps={{ style: { display: "none" } }}
                closable={false}
                maskClosable={true}
                width={800}>
                <Anchor
                    affix={false}
                    direction='horizontal'
                    items={nodesData}
                    onChange={getDivComponent}
                />
                <br />
                <div id='main'>
                    <Form
                        form={form}
                        name="Edit">
                        <Row gutter={[8, 0]}>
                            {divCmpt}
                            {divCmpt2}
                            {buttonCmpt}
                        </Row>
                    </Form>
                </div>
            </AntDraggableModal>
        </>
    )
};

/**
 * 获取默认文件服务器
 */
Configs.getDefaultFilesServer = async () => {
    let res: any = {
        success: false,
        data: undefined
    };
    const configNodesRes = await fetchApi.GET(defaultService,
        {
            apiId: 'configNodes',
            apiExtend: 'getConfig',
            apiData: {
                id: 'config',
                nodeId: 'general'
            }
        })
    if (configNodesRes.success === true) {
        const { filesServerId } = configNodesRes.data;
        const filesServerRes = await fetchApi.GET(defaultService,
            {
                apiId: 'filesServer',
                apiVariable: filesServerId
            })
        res = filesServerRes
    }
    return res;
}

export default Configs;
